import { on_ready, is_elementor_edit } from 'js/utils';
import { add_to_cart_ajax_init } from 'src/woocommerce/js/add_to_cart';
// import responsive_tabs from '../../components/tabs/responsive-tabs';

import './internals.scss';
console.log('internals.js');

on_ready(()=>{
    if( is_elementor_edit() ) return;
    add_to_cart_ajax_init();
    // responsive_tabs();
});